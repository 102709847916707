html,
body,
body > div {
  height: 100%;
}

.loadingContainer {
  color: white;
  min-height: 100vh;
  display: flex;
  place-content: center center;
  flex-direction: column;
}

.slideShowPage {
  text-align: center;
  background-color: var(--slideshow-bg-color, #000);
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0;
  max-width: unset;
  overflow: hidden;
  height: 100vh;
}

.slideShowContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.noImageContainer {
  color: white;
  min-height: 100vh;
  display: flex;
  place-content: center center;
  flex-direction: column;
}

:global {

  .image-gallery {
    display: flex;
    flex-grow: 1;
    max-height: 100%;

    .image-gallery-content {
      display: flex;
      flex-grow: 1;


      div.image-gallery-slide-wrapper {
        max-width: 100%;
        max-height: calc(100vh - var(--slideshow-banner-height, 0px));
        display: inline-flex;
        align-items: center;
        padding: 0 10px;

        .image-gallery-swipe {
          display: flex;
          width: 100%;
        }
      }

      .image-gallery-slides {
        width: 100%;
        display: flex;
        align-self: center;

        div.image-gallery-image {
          max-height: calc(100vh - var(--slideshow-banner-height, 0px) - 20px);
          display: flex;
          justify-content: center;

          > * {
            display: flex;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .image-gallery-slide {
        opacity: 0;
        transition: opacity calc(var(--slideshow-transition-duration, 400) * 1ms) var(--slideshow-transition, ease-in-out) !important;
      }

      .image-gallery-slide.center {
        opacity: 1;
      }
    }

    .image-gallery-thumbnails-wrapper {
      width: 30%;
      margin: 0;

      .image-gallery-thumbnails {
        display: flex;
        justify-content: flex-end;
        height: 100%;
        width: 100%;
        left: 0;
        padding: 0;
        position: absolute;
        top: 0;
      }

      .image-gallery-thumbnails-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        padding: 10px;
        place-items: center;
        height: fit-content;
        max-height: calc(100vh - var(--slideshow-banner-height, 0px));
      }

      .image-gallery-thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        margin: 0 !important;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .banner {
    background: lightgreen;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    text-align: center;

    img {
      display: flex;
      width: 100%;
    }
  }
}
