.innerBar {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 0 3px 0 rgba(0, 0, 0, .12);
}

.iconResize {
  color: var(--gray);
}
