:root {
  --primary-text: #4E5155;
  --gray: #898a8c;

  /* Design System */
  --theme-yellow: #FCB825;
  --theme-yellow-dark: #FFAe20;
  --theme-purple: #5b68b2;
  --theme-green-light: #50BE8D;
  --theme-green: #11A048;
  --theme-blue: #3373E4;
  --theme-red: #e34034;
  --theme-gray: #30303D;
  --theme-gray-dark: #283238;
  --theme-gray-medium-dark: #616165;
  --theme-gray-medium: #828282;
  --theme-gray-medium-light: #B7B8BA;
  --theme-gray-medium-light-2: #DBDBDC;
  --theme-gray-light: #ECECED;
  --theme-gray-light-ultra: #F2F2F3;
}

body {
  background: white;
}

h2 {
  color: var(--theme-gray);
  font-weight: normal;
  font-size: 25px;
}

h3 {
  color: var(--theme-gray);
  font-weight: 500;
  font-size: 20px;
}

h4 {
  color: var(--theme-gray);
  font-weight: normal;
  font-size: 18px;
}

h5 {
  color: var(--theme-gray);
  font-weight: 500;
  font-size: 14px;
}

h6 {
  color: var(--theme-gray);
  font-weight: 600;
  font-size: 14px;
}

.form-group select,
.form-group input {
  height: 27px;
  padding: 0 10px;
  border-radius: 5px;
  color: var(--theme-gray-medium-dark);
  font-size: 12px;
  background-position: calc(100% - 8px);

  &:focus {
    border-color: var(--theme-yellow);
  }
}

.switcher-indicator,
button {
  box-shadow: none;
  outline: none !important;
  font-family: inherit;
}

.dropdown-menu.show {
  box-shadow: 0 4px 20px rgba(0, 0, 0, .3);
  border-radius: 7px;
}

label.switcher {
  min-height: 20px;

  .switcher-indicator {
    height: 20px;
    width: 36px;

    &::after {
      height: 14px;
      width: 14px;
      margin: 3px;
    }
  }

  &.labelFirst {
    display: inline-flex;
    margin: 0;
    padding-right: 2.375rem;
    padding-left: 0;
    cursor: pointer;

    .switcher-label {
      padding-left: 0;
      padding-right: .5rem;
      color: var(--theme-gray-medium-dark);
      font-size: 14px;
      line-height: 20px;
    }

    .switcher-indicator {
      left: unset;
      right: 0;
    }

    .switcher-label,
    .switcher-indicator {
      cursor: pointer;
      user-select: none;
    }
  }
}

.selectCircle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  align-self: flex-end;

  &::after {
    content: "";
    transform: translate(-3px, -3px);
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: 1px solid var(--gray);
  }

  &.active {
    background-color: var(--theme-yellow);

    &::after {
      border: 1px solid var(--theme-yellow);
    }
  }
}

.pageHeader {
  display: flex;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid var(--theme-gray-light-ultra);
  flex-flow: wrap;

  h4 {
    display: flex;
    font-size: 25px;
    line-height: 30px;
    margin: 0 20px 0 0;
    color: var(--theme-gray-dark);
    font-weight: normal;
  }
}

.react-bootstrap-table {
  border: 1px solid #F2F2F3;
  border-radius: 10px;

  .table {
    border: 0;
    margin-bottom: 0;

    th {
      font-weight: bold;
      font-size: 12px;
      text-transform: capitalize;
      color: var(--theme-gray-dark);
      outline: 0;
    }

    th,
    td {
      padding: 10px 20px;

      &.hover-edit-background:hover {
        background: calc(100% - 10px) center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 512 512'%3E%3Cpath fill='%2330303d' d='M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z'/%3E%3C/svg%3E") no-repeat;
        background-size: 14px;
        cursor: pointer;
      }
    }

    .custom-control-label::before {
      background-position: 3px 4px;
    }
  }


  .table tbody {
    color: #000;

    tr:nth-child(odd) {
      background-color: var(--theme-gray-light-ultra);
    }

    td {
      font-size: 12px;
      line-height: 18px;
      padding: 5px 10px;
      font-weight: normal;
      color: #000;
      font-family: inherit;
      vertical-align: middle;
    }

    .react-bs-table-no-data {
      background-color: white;

      p {
        margin: 20px
      }
    }

    textarea {
      border: 1px solid var(--theme-gray-medium-light-2);
      border-radius: 5px;
      outline: none;

      &:focus {
        border-color: var(--theme-yellow);
      }
    }
  }


  thead:first-child > tr > th {

    &.sortable span {
      float: right;

      &::before,
      &::after {
        font-size: 16px;
        line-height: 12px;
        font-weight: 300;
        opacity: 1;
        font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        color: #B7B8BA;
      }

      &::after {
        margin-left: 1px;
      }

      &.caret-4-asc::before { color: var(--theme-yellow)}

      &.caret-4-desc::after { color: var(--theme-yellow)}
    }
  }

  &.searchFilter .table thead:first-child > tr > th {

    input,
    select {
      border: 1px solid var(--theme-gray-medium-light-2);
      border-radius: 5px;
      min-height: 27px;
      font-size: 14px;
      height: initial;
      outline: none;

      &:focus {
        border-color: var(--theme-yellow);
      }
    }

    input {
      padding: 0 24px 0 10px;
      background: calc(100% - 7px) center url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' role='img' viewBox='0 0 512 512'%3E%3Cpath xmlns='http://www.w3.org/2000/svg' fill='%2330303d' d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E") no-repeat;
      background-size: 12px;
      cursor: pointer;
    }

    select {
      padding: 0 5px;
    }
  }

  &.no-round-bottom {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.react-bootstrap-table-pagination {
  border: 1px solid #F2F2F3;
  border-top: 0;
  margin: 0;
  padding: 20px !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  .col-md-6,
  .col-xs-6,
  .col-sm-6,
  .col-lg-6 {
    padding: 0;
  }

  button {
    min-height: 30px;
    padding: 3px 15px;
  }

  a {
    min-height: 30px;
    min-width: 30px;
    line-height: 28px;
    padding: 0;
  }

  .react-bs-table-sizePerPage-dropdown {
    margin-right: 20px;
    display: inline-block;
  }

  .dropdown-menu {
    padding: 5px 10px;
    width: 100%;
    min-width: 50px;
  }
}

.bootstrap-table-checkbox {
  margin: 0;
  vertical-align: middle;
  padding-left: 18px;
}

.bootstrap-table-checkbox .custom-control-label {
  cursor: pointer;
  width: 0;
  height: 18px;

  &::before {
    border: 1.5px solid #B7B8BA;
    width: 18px;
    height: 18px;
  }
}

.react-bs-table-sizePerPage-dropdown .btn {
  padding-left: 28px;
  padding-right: 28px;
}

.recharts-tooltip-wrapper {
  z-index: 1;
}

.custom-control.custom-checkbox .custom-control-label::before {
  width: 18px;
  height: 18px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: none !important;
}

.btn.small {
  padding: 3px 18px;
  min-height: 30px;

  &.ladda-button {
    transition: unset !important;
  }
}

.pagination {
  margin-bottom: 0 !important;

  li {
    cursor: pointer;

    a {
      margin: 0 4px;
      border-radius: 50% !important;

      &:focus,
      :active,
      :target,
      :hover {
        outline: 0;
      }
    }

    &.active {

      a {
        background-color: var(--theme-yellow) !important;
        border-color: var(--theme-yellow) !important;

        &:focus,
        :active,
        :target,
        :hover {
          outline-color: unset !important;
        }
      }
    }
  }
}

.swal2-container.swal2-shown {

  .swal2-popup {
    min-width: 275px;
    border-radius: 7px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, .3);

    &.max-content {
      width: max-content;
    }

    .swal2-icon {
      margin: 0 auto 20px;
    }

    .swal2-header .swal2-title {
      margin: 0 20px 20px;
      color: var(--theme-gray-dark);
    }
  }

  .swal2-content {
    color: var(--theme-gray-medium-dark);
    line-height: 22px;
    font-weight: normal;
    font-size: 16px;
    margin: 0;
  }

  .swal2-actions {
    flex-direction: row-reverse;
    margin-top: 20px;
  }

  .btn {
    height: 30px;
    font-size: 0.894rem;
    padding: 0 20px;
    margin: 0;

    &:last-child:not(:first-child) {
      margin: 0 15px 0 0;
    }

    &.swal2-cancel {
      color: var(--theme-gray-medium-dark);
      border-color: var(--theme-gray-medium-light-2);
      background-color: unset;

      &:hover {
        background-color: var(--theme-gray-light-ultra);
      }
    }

    &.swal2-confirm:not(.btn-primary) {
      background-color: var(--theme-red);

      &:hover {
        border: 1px solid var(--theme-red);
        color: var(--theme-red);
        background-color: white;
      }
    }
  }
}

.modal-dialog {
  min-width: 275px;
  width: min-content;
  margin: auto;

  .modal-content {
    border-radius: 10px;

    .modal-header {
      padding: 10px 20px;
      border: 0;

      h3 {
        margin: 10px 0;
        color: var(--theme-gray-dark);
      }
    }

    .modal-body {
      padding: 0 20px;
    }

    .modal-footer {
      border: 0;
      padding: 20px;
      justify-content: center;

      button {
        min-width: 80px;
        margin: 0;

        &:last-child:not(:first-child) {
          margin-left: 15px;
        }
      }
    }
  }
}

.modal-header .close,
.swal2-close {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;
  padding: 15px 10px 10px 5px;
  line-height: 0;
  z-index: 1;
  background: unset;
  color: var(--theme-gray-medium-dark);
  transform: unset;
  transition: .3s ease-in-out opacity;
  font-weight: bold;
  opacity: 1;
  font-family: inherit;
  font-size: 22px;

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--theme-gray-medium-dark);
    opacity: .5;
  }
}

@keyframes spinner-border-rtl {

  to { transform: rotate(-360deg); }
}

span.spinner-border,
div.spinner-border {
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: text-bottom;
  border: 5px solid var(--theme-yellow);
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border-rtl .75s linear infinite;
  box-sizing: border-box;
}

.tooltip-inner {
  max-height: calc(100vh - 12px);
  overflow: auto;
  pointer-events: auto;
}

.btn-primary {
  border-color: transparent;
  background: var(--theme-yellow);
  color: var(--theme-gray);
  font-weight: 500;

  &:hover,
  &:active {
    box-shadow: 0 0 4px 0 var(--theme-gray);
    background: var(--theme-yellow);
    color: var(--theme-gray);
    font-weight: 500;
  }
}

.btn-outline-primary {
  border-color: rgba(48, 48, 61, .7);
  background: white;
  color: var(--theme-gray);
  font-weight: 500;

  &:hover {
    border-color: transparent;
    background: #FCB825;
    color: var(--theme-gray);
    font-weight: 500;
  }

  &:disabled {
    border-color: var(--theme-gray) !important;
    background: transparent;
    color: var(--theme-gray) !important;
    cursor: not-allowed;
  }
}
