
.mappingForm {
  display: flex;
  flex-grow: 1;
  width: 450px;
  min-width: 450px;
  max-width: 450px;
  flex-direction: column;
  border: 1px solid #F2F2F3;

  .positioning {
    display: flex;
    background: white;
    flex-direction: column;
    height: fit-content;
    width: 100%;
    font-weight: 400;
    padding: 8px;

    .rangeContainer {
      display: flex;
      height: 22px;

      span {
        font-size: 13px;

        &:first-child {
          width: 52px;
        }

        &:last-child {
          width: 70px;
          text-align: right;
        }
      }

      > div {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;

         > div {
           width: 100%;
         }
      }
    }
  }
}

.circleButton {
  color: var(--theme-gray);
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background: transparent;
  border-radius: 50%;
  border: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-right: 8px;
  }

  &:hover {
    opacity: .8;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    color: grey;
  }
}

:global {
  .noUi-base::before {
    content: "";
    display: flex;
    position: absolute;
    inset: -6px 0;
  }
  .noUi-handle::before {
    inset: -4px
  }
}