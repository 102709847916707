@-webkit-keyframes RouterLoaderAnimation {

  0% { opacity: 0; }

  100% { opacity: 1; }
}

@-moz-keyframes RouterLoaderAnimation {

  0% { opacity: 0; }

  100% { opacity: 1; }
}

@keyframes RouterLoaderAnimation {

  0% { opacity: 0; }

  100% { opacity: 1; }
}

.Container {
  position: fixed;
  z-index: 99999999;
  inset: 0;
  opacity: 0;
  background: rgba(#fff, .25);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: RouterLoaderAnimation .2s;
  animation-fill-mode: forwards;
}

.Spinner {
  display: block;
}

:global(.app-loading) .Container {
  display: none;
}
