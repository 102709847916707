.navbar.layout-navbar {
  box-shadow: 0 3px 14px rgba(0, 0, 0, .07);
  z-index: 10;

  .app-brand {
    justify-content: center;

    .logo-container {
      height: 40px;
      display: flex;

      .app-brand-text {
        height: inherit;
      }
    }
  }

  .toggle-button {
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .3);
  }

  .separator {
    margin: 0 20px;
  }
}

@media (max-width: 992px) {

  .navbar-nav {
    width: 100%;

    &:first-child {
      justify-content: space-between;
    }

    &:last-child {
      justify-content: flex-end;
    }

    > div {
      margin: 0 !important;
    }


    .dropdown-menu.show {
      position: absolute;
      right: 0;
      left: unset;
    }
  }
}
