@media print {

  /* this will remove date and title of the PDF */
  @page {
    margin: 5mm;
  }

  .pageHeader {

    > *:not([class*="EventSelectorButtonContainer"]) {
      display: none;
    }

    [class*="EventSelectorButtonContainer"] {
      margin: auto;

      button:not(:nth-child(2)) {
        display: none;
      }

      button:nth-child(2) {
        border: 0;
        font-size: 20px;
        Color: black;
      }
    }
  }

  .print-break-page {
    display: block;
    page-break-before: always;
  }

  .hide-on-print {
    display: none;
  }

  .display-block-on-print {
    display: block;

    h4 {
      font-size: 20px;
    }
  }

  .bigger-text-on-print tspan {
    font-size: 13px;
  }

  .print-brizi-vision {

    .recharts-layer.recharts-label-list text {
      fill: var(--theme-blue);
    }

    [class*="gradient"] {
      fill: var(--theme-blue);
      background-image: none;
    }
  }

  .card {
    box-shadow: none !important;

    &[class*="ChartCard"] {
      min-height: 100px;

      .recharts-responsive-container,
      .recharts-wrapper {
        height: 250px !important;

        svg {
          max-height: 100%;
        }
      }
    }
  }

  [class*="OverlayListWrapper"] {
    margin-top: 20px;
  }
}

