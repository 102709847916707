.tableWrapper {

  :global {

    .card {
      border-radius: 10px;
    }

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 0;
    }

    .card-body {
      border-top: 1px solid var(--theme-gray-light-ultra);
    }

    .react-bootstrap-table {
      border: 0 !important;
    }
  }
}

.rotated {
  transform: rotate(180deg);
}
