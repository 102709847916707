.layout-sidenav {
  background-color: var(--theme-gray) !important;
  box-shadow: 0 0 0 1px rgba(51,51,51,.06);
  color: white;
  font-weight: bold;

  .sidenav-item {
    margin: 0 !important;
    color: white;

    &.active > .sidenav-link {
      opacity: 1;
      color: var(--theme-yellow);
    }

    .sidenav-link {
      opacity: .75;
      font-size: 1.2em;
      display: flex;
      min-height: 40px;
      color: white;
      justify-content: flex-start;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }

      &::before {
        background-color: white !important;
      }

      .sidenav-icon {
        width: 2.25rem;
        margin-right: 0;
        font-size: 1.25rem;
      }
    }
  }

  .sidenav-menu {
    padding-top: 0 !important;

    .sidenav-item {

      &:not(.active) {
        font-weight: normal;
      }

      .sidenav-link {
        padding: 0 0 0 3.25rem;
      }
    }

    &::before {
      color: #8897AA;
    }
  }
}

